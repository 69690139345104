<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col class="text-left">
                        <v-btn text :to="{name: 'admin.documents.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                        <v-col>
                            <v-text-field
                                type="text"
                                label="Name in russian"
                                placeholder="Please enter a name in russian" outlined
                                v-model="document.name_ru"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                type="text"
                                label="Name in english"
                                placeholder="Please enter a name in english" outlined
                                v-model="document.name_en"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                type="text"
                                label="Name in armenian"
                                placeholder="Please enter a name in armenian" outlined
                                v-model="document.name_hy"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div class="d-inline">
                                <v-row>
                                    <v-col cols="1">
                                        <v-file-input
                                            :loading="uploading.loading"
                                            :disabled="uploading.loading"
                                            hide-input
                                            outlined v-model="file.value"
                                            @change="inputFile"
                                        />
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            label="File"
                                            readonly
                                            :rules="notEmpty"
                                            disabled
                                            :value="file.name"
                                        />
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row v-if="document.created_at">
                        <v-col>
                            <v-text-field
                                label="Created at"
                                outlined
                                readonly
                                disabled
                                v-model="formatDate"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn
                                block
                                color="green"
                                @click="handleSaveDocument"
                            >
                                Save Changes
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <v-snackbar
            v-model="snackbar"
            color="green"
            :timeout="timeout_snackbar"
            v-text="text_snackbar"
        />
        <v-snackbar
            v-model="uploading.snackbar"
            :color="uploading.success? 'green':'danger'"
            :timeout="timeout_snackbar"
            v-text="uploading.text"
        />

    </admin-dashboard-layout>
</template>

<script>
import Document from "../../../models/Document";
import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";

export default {
    name: "documents-show",
    components: {AdminDashboardLayout},
    data() {
        return {
            formatDate: 0,
            flagSaveUpdate: false,
            document: {},
            valid: false,
            notEmpty: [v => !!v || 'This field must not be empty'],
            snackbar: false,
            text_snackbar: 'Saved successfully',
            timeout_snackbar: 2000,
            file: {
                value: null,
                name: null
            },

            uploading: {
                loading: false,
                snackbar: false,
                text: null,
                success: false,
            }
        }
    },

    methods: {
        async handleSaveDocument() {
            this.formValid = this.$refs.form.validate()

            if (this.formValid) {
                const document = await new Document(this.document)

                this.document = this.flagSaveUpdate
                    ? await document.patch()
                    : await document.save()

                await this.$router.push({name: 'admin.documents.show', params: {id: this.document.id}})

                this.snackbar = true
            }
        },
        returnFormDate(uc) {
            this.formatDate = new Date(uc).toLocaleString()
        },
        inputFile() {
            this.uploadFile();
        },
        async uploadFile(){
            if (this.uploading.loading) return;
            if (!(this.file.value instanceof Blob)) return;
            this.uploading.loading = true;

            const data = new FormData();
            data.append('file', this.file.value);

            const {url, success, message} = await window.axios.post('/api/documents/upload', data)
                .then(res => ({
                    url: res.data,
                    success: !!res.data,
                    message: res.data?'Uploaded successfully': 'Something went wrong'
                }))
                .catch(e => ({
                    url: null,
                    success: false,
                    message: e.message
                }));
            this.uploading.text = message;
            this.uploading.success = success;
            this.uploading.snackbar = true;
            this.uploading.loading = false;
            this.file.value = null;
            this.file.name = url;
            this.document.url = url;
        }
    },

    async mounted() {
        const documentId = this.$route.params.id
        if (documentId) {
            this.document = await Document.find(documentId)
            this.flagSaveUpdate = true
            this.returnFormDate(this.document.created_at)
        }
        this.file.name = this.document.url ?? null
    }
}
</script>

<style scoped>

</style>
